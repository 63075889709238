<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <section class="content-home">
        <div class="container-fluid border-bottom-red">
            <div class="row">
                <div class="col-2">
                    <div class="content-block">
	    				<img class="icon-red img-left" src="../../assets/images/berita-terbaru-w.svg" alt="icon">
					</div>
                </div>

                <div class="col-10">
                    <div class="content-block">
                        <h3>Latest <span class="alternate">News</span></h3>

                        <div class="row">
                            <div class="col-lg-8 ms-auto me-auto">
                                <div v-if="jenis === 'video'">
                                    <div class="berita">
                                        <video width="300" height="auto" class="img-fluid mb-3" controls>
                                            <source :src="coverPath + cover">
                                        </video>

                                        <article class="berita-post-single">
                                            <div class="post-contents">
                                                <div class="post-title">
                                                    <h6>
                                                        <a>
                                                            {{judul}}
                                                        </a>
                                                    </h6>
                                                </div>

                                                <div class="text-start">
                                                    <p>
                                                        <span class="post-date float-left">
                                                            {{waktu}}
                                                        </span>
                                                    </p>
                                                </div>
                                                <p v-html="berita"></p>
                                            </div>
                                        </article>
                                    </div>
                                </div>

                                <div v-else>
                                    <div class="berita">
                                        <img :src="coverPath + cover" alt="images" class="img-fluid post-thumb mb-3">

                                        <article class="berita-post-single">
                                            <div class="post-contents">
                                                <div class="post-title">
                                                    <h6>
                                                        <a>
                                                            {{judul}}
                                                        </a>
                                                    </h6>
                                                </div>

                                                <div class="text-start">
                                                    <p>
                                                        <span class="post-date float-left">
                                                            {{waktu}}
                                                        </span>
                                                    </p>
                                                </div>
                                                <p v-html="berita"></p>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'pageRead',
    components: {
        Banner,
    },
    props: [
        'id'
    ],
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
            berita: [],
            judul: '',
            berita: '',
            waktu: '',
            cover: '',
            jenis: '',
            // coverPath: 'http://localhost:8080/assets/berita/',
            coverPath: 'https://api.plnbag.co.id/assets/berita/',
            dataBerita: [],
            total: 0,
        }
    },
    mounted () {
        this.loadBanner()
        this.loadBerita()
        this.listBerita()
    },
    methods: {
        loadBanner () {
            axios.get('berita_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_en
                })
        },

        loadBerita () {
            axios.get('berita_baca?idberita=' + this.id)
                .then((res) => {
                    this.judul = res.data.data.judul
                    this.berita = res.data.data.berita
                    this.waktu = res.data.data.waktu
                    this.cover = res.data.data.cover
                    this.jenis = res.data.data.jenis
                })
        },

        listBerita () {
            axios.get('berita_list')
                .then((res) => {
                    this.dataBerita = res.data.data
                    this.total = res.data.count
                })
        }
    }
}
</script>

<style scoped>
    @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Montserrat:300,400,500,700");

    h1, h2, h3, h4, h5, h6 {
        font-family: "Montserrat", sans-serif;
        color: #222222;
        font-weight: 600;
    }

    h1 {
        font-size: 2.8rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 2.25rem;
    }

    h4 {
        font-size: 1.6875rem;
    }

    h5 {
        font-size: 1.375rem;
    }

    h6 {
        font-size: 1.25rem;
    }

    p, a, li, blockquote, label {
        font-size: 1rem;
        line-height: 26px;
        color: #848484;
        margin-bottom: 0;
    }

    .content-home {
        position: relative;
    }

    .content-home .content-block {
        padding: 25px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .image-block {
        position: absolute;
        right: 5%;
        bottom: 0;
    }

    @media (max-width: 767px) {
        .content-home .image-block {
            display: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    span.alternate {
        color: #ea2e23;
        font-weight: 800;
    }

    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-left {
            width: 45px;
            width: auto;
        }
    }

    .berita .berita-post,
    .berita .berita-post-single {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        margin-bottom: 15px;
    }

    .berita .berita-post-alt {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        margin-bottom: 25px;
        padding-bottom: 20px;
        border-bottom: 2px solid red;
    }

    .armada .armada-post {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
    }

    .berita .berita-post .post-thumb,
    .berita .berita-post-alt .post-thumb,
    .berita .berita-post-single .post-thumb {
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
    }

    .berita .berita-post .post-thumb img {
        /*width: 100%;*/
        position: absolute;
        border-radius: 15px;
        /*clip: rect(0px, 100px, 100px, 0px);*/
    }

    .berita .berita-post-alt .post-thumb img {
        position: absolute;
        border-radius: 15px;
        border: 2px solid #ea2e23;
    }

    .berita .berita-post-single img.post-thumb {
        border-radius: 15px;
        margin-right:15px;
        float: left;
        height: 115px;
        width: 175px;
    }

    .armada .armada-post .post-thumb img {
        position: absolute;
        border-radius: 15px;
        border: 2px solid #fff;
    }

    .berita .berita-post .post-contents,
    .berita .berita-post-alt .post-contents,
    .berita .berita-post-single .post-contents,
    .armada .armada-post .post-contents {
        /*-ms-flex-preferred-size: 50%;
            flex-basis: 50%;*/
        padding: 0px 10px;
        position: relative;
        /*border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: #e5e5e5;*/
    }

    .berita .berita-post .post-contents .post-date span {
        font-weight: 600;
        position: relative;
        font-size: 13px;
        color: #ea2e23;
    }

    .berita .berita-post-alt .post-contents .post-date span {
        font-weight: 600;
        position: relative;
        font-size: 13px;
        color: #3a3a3a;
    }

    .berita .berita-post-single .post-contents span.post-date {
        font-weight: 600;
        position: relative;
        font-size: 15px;
        color: #ea2e23;
    }

    .berita .berita-post .post-contents .post-title h6,
    .berita .berita-post-alt .post-contents .post-title h6,
    .berita .berita-post-single .post-contents .post-title h6 {
        margin-bottom: 15px;
        /*margin-top: 5px;*/
        line-height: 15px;
    }

    .berita .berita-post .post-contents .post-title h6 a {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        font-size: 13px;
        color: #0158a7;
        line-height: 5px;
    }

    .berita .berita-post-alt .post-contents .post-title h6 a {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        font-size: 13px;
        color: #ea2e23;
        line-height: 5px;
    }

    .berita .berita-post-single .post-contents .post-title h6 a {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        font-size: 21px;
        color: #ea2e23;
        line-height: 21px;
    }

    .berita .berita-post .post-contents .post-exerpts p,
    .berita .berita-post-alt .post-contents .post-exerpts p {
        font-size: 12px;
        line-height: 15px;
    }

    .berita .berita-post-single .post-contents .post-exerpts p {
        font-size: 15px;
        line-height: 18px;
    }

    .berita .berita-post .post-contents .date {
        margin-top: 17px;
        width: 65px;
        height: 65px;
        text-align: center;
        background: #ff6600;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }

    .berita .berita-post .post-contents .date h4 {
        color: #fff;
        font-weight: 400;
    }

    .berita .berita-post .post-contents .date h4 span {
        display: block;
        font-size: 1rem;
        font-weight: 400;
    }

    .berita .berita-post .post-contents .more,
    .berita .berita-post-alt .post-contents .more,
    .berita .berita-post-single .post-contents .more {
        text-align: right;
        margin-top: -15px;
    }

    .berita .berita-post .post-contents .more a {
        font-family: "Montserrat", sans-serif;
        color: #ea2e23;
        font-size: 12px;
    }

    .berita .berita-post-alt .post-contents .more a {
        font-family: "Montserrat", sans-serif;
        color: #0158a7;
        font-size: 12px;
    }

    .berita .berita-post-single .post-contents .more a {
        font-family: "Montserrat", sans-serif;
        color: #0158a7;
        font-size: 15px;
    }

    @media (max-width: 767px) {
        .berita .berita-post .post-contents,
        .berita .berita-post-alt .post-contents,
        .berita .berita-post-single .post-contents {
            padding-right: 0;
        }
    }

    .img-berita {
        width: 125px;
        height: auto;
        position: relative;
        border-radius: 15px;
        border: 2px solid #ea2e23;
    }

    @media screen and (max-width: 767px) {
        .img-berita {
            width: 55px;
            height: auto;
        }
    }
    
</style>